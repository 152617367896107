$(document).ready(function() {
    var max_fields      = 10; //maximum input boxes allowed
    var wrapper   		= $(".input_fields_wrap"); //Fields wrapper
    var add_button      = $(".add_field_button"); //Add button ID

    var x = 1; //initlal text box count
    $(add_button).click(function(e){ //on add input button click
        e.preventDefault();
        if(x < max_fields){ //max input box allowed
            x++; //text box increment
            $(wrapper).append('' +
                '<div class="input_fields_wrap">\n' +
                '    <div class="uk-margin">\n' +
                '    <label class="uk-form-label" for="form-horizontal-text">Odpowiedź</label>\n' +
                '    <div class="uk-form-controls">\n' +
                '    <textarea name="answer[]" rows="4" id="form-horizontal-text" class="uk-textarea"></textarea>\n' +
                '    </div>\n' +
                '    </div>\n' +
                '    <button class="remove_field uk-button uk-button-danger uk-button-small">Usuń</button>' +
                '</div>'); //add input box
        }
    });

    $(wrapper).on("click",".remove_field", function(e){ //user click on remove text
        e.preventDefault(); $(this).parent('div').remove(); x--;
    })
});





