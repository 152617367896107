import $ from "jquery";

$(document).ready(function() {
    $('.table-filter').DataTable( {
        "language": {
            "sProcessing":   "Przetwarzanie...",
            "sLengthMenu":   "Pokaż _MENU_ pozycji",
            "sZeroRecords":  "Nie znaleziono pasujących pozycji",
            "sInfoThousands":  " ",
            "sInfo":         "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
            "sInfoEmpty":    "Pozycji 0 z 0 dostępnych",
            "sInfoFiltered": "(filtrowanie spośród _MAX_ dostępnych pozycji)",
            "sInfoPostFix":  "",
            "sSearch":       "",
            "sUrl":          "",
            "oPaginate": {
                "sFirst":    "Pierwsza",
                "sPrevious": "Poprzednia",
                "sNext":     "Następna",
                "sLast":     "Ostatnia"
            },
            "sEmptyTable":     "Brak danych",
            "sLoadingRecords": "Wczytywanie...",
            "oAria": {
                "sSortAscending":  ": aktywuj, by posortować kolumnę rosnąco",
                "sSortDescending": ": aktywuj, by posortować kolumnę malejąco"
            }
        }
    });






    $('.table-simple').DataTable(
        {
            "ordering": false,
            "info":     false,
            "language": {
                "sProcessing":   "Przetwarzanie...",
                "sLengthMenu":   "Pokaż _MENU_ pozycji",
                "sZeroRecords":  "Nie znaleziono pasujących pozycji",
                "sInfoThousands":  " ",
                "sInfo":         "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
                "sInfoEmpty":    "Pozycji 0 z 0 dostępnych",
                "sInfoFiltered": "(filtrowanie spośród _MAX_ dostępnych pozycji)",
                "sInfoPostFix":  "",
                "sSearch":       "",
                "sUrl":          "",
                "oPaginate": {
                    "sFirst":    "Pierwsza",
                    "sPrevious": "Poprzednia",
                    "sNext":     "Następna",
                    "sLast":     "Ostatnia"
                },
                "sEmptyTable":     "Brak danych",
                "sLoadingRecords": "Wczytywanie...",
                "oAria": {
                    "sSortAscending":  ": aktywuj, by posortować kolumnę rosnąco",
                    "sSortDescending": ": aktywuj, by posortować kolumnę malejąco"
                }
            }
        }
    );

    $(".dataTables_filter input").attr("placeholder", "Szukaj");


    $('.table-filter-desc').DataTable( {
        "language": {
            "sProcessing":   "Przetwarzanie...",
            "sLengthMenu":   "Pokaż _MENU_ pozycji",
            "sZeroRecords":  "Nie znaleziono pasujących pozycji",
            "sInfoThousands":  " ",
            "sInfo":         "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
            "sInfoEmpty":    "Pozycji 0 z 0 dostępnych",
            "sInfoFiltered": "(filtrowanie spośród _MAX_ dostępnych pozycji)",
            "sInfoPostFix":  "",
            "sSearch":       "",
            "sUrl":          "",
            "oPaginate": {
                "sFirst":    "Pierwsza",
                "sPrevious": "Poprzednia",
                "sNext":     "Następna",
                "sLast":     "Ostatnia"
            },
            "sEmptyTable":     "Brak danych",
            "sLoadingRecords": "Wczytywanie...",
            "oAria": {
                "sSortAscending":  ": aktywuj, by posortować kolumnę rosnąco",
                "sSortDescending": ": aktywuj, by posortować kolumnę malejąco"
            }
        },
        "order": [[ 0, "desc" ]]
    });

    $('.table-filter-desc-full').DataTable( {
        "language": {
            "sProcessing":   "Przetwarzanie...",
            "sLengthMenu":   "Pokaż _MENU_ pozycji",
            "sZeroRecords":  "Nie znaleziono pasujących pozycji",
            "sInfoThousands":  " ",
            "sInfo":         "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
            "sInfoEmpty":    "Pozycji 0 z 0 dostępnych",
            "sInfoFiltered": "(filtrowanie spośród _MAX_ dostępnych pozycji)",
            "sInfoPostFix":  "",
            "sSearch":       "",
            "sUrl":          "",
            "oPaginate": {
                "sFirst":    "Pierwsza",
                "sPrevious": "Poprzednia",
                "sNext":     "Następna",
                "sLast":     "Ostatnia"
            },
            "sEmptyTable":     "Brak danych",
            "sLoadingRecords": "Wczytywanie...",
            "oAria": {
                "sSortAscending":  ": aktywuj, by posortować kolumnę rosnąco",
                "sSortDescending": ": aktywuj, by posortować kolumnę malejąco"
            }
        },
        "order": [[ 0, "desc" ]],
        "pageLength": 2000
    });





});