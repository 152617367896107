/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;

console.log(55);

$(function() {
    console.log(99);
});
import 'jquery-ui/ui/widgets/datepicker.js';

require('uikit/dist/js/uikit.min');
require('uikit/dist/js/uikit-icons.min');
require('@fortawesome/fontawesome-free/js/fontawesome.min');


require('./scripts/anwser-add-input');
require('datatables');
require('./scripts/datatables');

jQuery(function($){
         $.datepicker.regional['pl'] = {
             closeText: 'Zamknij',
             prevText: '&#x3C;Poprzedni',
             nextText: 'Następny&#x3E;',
             currentText: 'Dziś',
             monthNames: ['Styczeń','Luty','Marzec','Kwiecień','Maj','Czerwiec', 'Lipiec','Sierpień','Wrzesień','Październik','Listopad','Grudzień'],
             monthNamesShort: ['Sty','Lu','Mar','Kw','Maj','Cze', 'Lip','Sie','Wrz','Pa','Lis','Gru'],
             dayNames: ['Niedziela','Poniedziałek','Wtorek','Środa','Czwartek','Piątek','Sobota'],
             dayNamesShort: ['Nie','Pn','Wt','Śr','Czw','Pt','So'],
             dayNamesMin: ['N','Pn','Wt','Śr','Cz','Pt','So'],
             weekHeader: 'Tydz',
             dateFormat: 'dd.mm.yy',
             firstDay: 1,
             isRTL: false,
             showMonthAfterYear: false,
             yearSuffix: ''
         };
         $.datepicker.setDefaults($.datepicker.regional['pl']);
});




$( function() {
    $( "#exam_date" ).datepicker({
        dateFormat: "yy-mm-dd",
        language: "pl"
    });
} );

$('#exam-form-create #question_schema').hide();

$('#exam-form-create select[name ="question_provider_id"]').change(function() {
    const question_provider_id = this.value;

    if (question_provider_id == 1) {
        $('#question_schema').hide();
    } else {
        $('#question_schema').show();
    }


});

$('#question-form select[name ="type_id"]').change(function() {

    const type_id_selected = this.value;

    const red_card = $('<div class="uk-margin" id="red_card">\n' +
        '                    <label class="uk-form-label" for="type_id">Czerwone kartki</label>\n' +
        '                    <div class="uk-form-controls">\n' +
        '                            <select class="uk-select" name="red_card">' +
        '                                   <option value="1">1</option>' +
        '                                   <option value="2">2</option>' +
        '                                   <option value="3">3</option></select>\n' +
        '                    </div>\n' +
        '                </div>');

    const yellow_card = $('<div class="uk-margin" id="yellow_card">\n' +
        '                    <label class="uk-form-label" for="type_id">Zółte kartki</label>\n' +
        '                    <div class="uk-form-controls">\n' +
        '                            <select class="uk-select" name="yellow_card">' +
        '                                   <option value="1">1</option>' +
        '                                   <option value="2">2</option>' +
        '                                   <option value="3">3</option>' +
        '                                   <option value="4">4</option>' +
        '                                   <option value="5">5</option>' +
        '                                   <option value="6">6</option></select>\n' +
        '                    </div>\n' +
        '                </div>');


    if (type_id_selected == 1) {

        $('#question-form').append(red_card);
        $('#question-form').append(yellow_card);

    } else {
        $('#red_card').remove();
        $('#yellow_card').remove();
    }

});

